'use strict';

import React     from 'react';
import PropTypes from 'prop-types';
import _         from 'lodash';
import 'whatwg-fetch';

import FetchUtils          from './../../advancd-fetch';
import OptionalInformation from './../optional-information';

const checkBrunnenringCompletelySelected = (brunnenring600, brunnenring800, brunnenring) => {
    if (!brunnenring600 && !brunnenring800) {
        return false;
    }

    if (!brunnenring) {
        return false;
    }

    return brunnenring !== 0;
}

class OptionalInformationData extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            allowNext: false,
            errorMessage: null,
            fieldsWithError: [],
            konusOrDeckplatteSelected: false,
            input: {
                leiter: null,
                konus: false,
                brunnenring: null,
                brunnenring600: false,
                brunnenring800: false,
                titleBlock: false,
                showTitleBlock: false,
                deckplatte: false,
                belastungsklasse: '',
                price: null,
                weight: null,
                opt_comment: '',
                NW: '',
                coverOption: '',
                brunnenringTypeOption: '',
                sealingTapeOption: '',
                coverArticleNumber: '',
                titleBlockOption: '',
                coverEntrance: ''
            },
            konusConfig: null,
            brunnenringConfig: null,
            brunnenring600Config: null,
            brunnenring800Config: null,
            deckplatteConfig: null,
            belastungsklasseConfig: null,
            laddersConfig: null,
            kommentarConfig: null,
            coverConfig: null,
            brunnenringTypeConfig: null,
            sealingTapeConfig: null,
            coverArticles: {},
            titleBlockConfig: null,
            titleBlocks: {},
            titleBlockNumber: '',
            coverEntranceConfig: null
        };

        this.load = this.load.bind(this);
        this.update = this.update.bind(this);
        this.handleNext = this.handleNext.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputChangeDestructured = this.onInputChangeDestructured.bind(this);
        this.allowNext = this.allowNext.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onInputCommentChange = this.onInputCommentChange.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);
        this.checkArticleId = this.checkArticleId.bind(this);
        this.stepId = 'optionalInformation';

        // pare this. because somehow we come through the constructor relatively often
        this.props.eventHandler.unbind('next-clicked', this.handleNext);
        this.props.eventHandler.bind('next-clicked', this.handleNext);
        this.props.eventHandler.unbind('back-clicked', this.onBack);
        this.props.eventHandler.bind('back-clicked', this.onBack);
    }

    _handleKeyDown(event) {
        let ENTER_CODE = 13;

        if (event.keyCode === ENTER_CODE) {
            event.preventDefault();

            this.allowNext();
            if (this.state.allowNext) {
                this.props.eventHandler.trigger('next-clicked');
            }

            return false;
        }
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._handleKeyDown);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._handleKeyDown);
        this.props.eventHandler.trigger('protocol');
        this.props.toggleProgressBar(this.props.showProgressBar);
        this.props.toggleSideProtocol(this.props.showSideProtocol);
        this.load();
    }

    handleNext() {
        this.update();
    }

    load() {
        fetch(`/api${this.props.location.pathname}`, {credentials: 'include'})
            .then((response) => {
                return FetchUtils.checkStatus(response);
            })
            .then(response => {
                this.props.eventHandler.trigger('update-progress-bar', {id: this.stepId, isCompleted: false});

                if (response.next) {
                    this.props.history.push(response.next);
                    return Promise.reject(new Error('done'));
                }

                let errorMessage = _.get(response, 'error.message');
                if (errorMessage) {
                    this.setState(prev => {
                        prev.errorMessage = errorMessage;

                        return prev;
                    })
                }

                this.setState(prev => {
                    prev.konusConfig = response.konus;
                    prev.deckplatteConfig = response.deckplatte;
                    prev.belastungsklasseConfig = response.belastungsklasse;
                    prev.laddersConfig = response.ladders;
                    prev.kommentarConfig = response.opt_comment;
                    prev.brunnenringConfig = response.brunnenring;
                    prev.brunnenring600Config = response.brunnenring600;
                    prev.brunnenring800Config = response.brunnenring800;
                    prev.coverConfig = response.cover;
                    prev.brunnenringTypeConfig = response.brunnenringType;
                    prev.sealingTapeConfig = response.sealingTape;
                    prev.titleBlockConfig = response.titleBlock;
                    prev.coverEntranceConfig = response.coverEntrance;
                    let coverEntranceDefault = 0;
                    if (prev.coverEntranceConfig && prev.coverEntranceConfig.default) {
                        coverEntranceDefault = prev.coverEntranceConfig.default;
                    }

                    if (!response.konus && response.deckplatte) {
                        prev.input.deckplatte = true;
                    }
                    if (!response.deckplatte) {
                        prev.input.konus = true;
                    }

                    if (response.inputs) {
                        prev.input.leiter = response.inputs.leiter;
                        prev.input.belastungsklasse = response.inputs.belastungsklasse;
                        prev.input.konus = response.inputs.konus || false;
                        prev.input.opt_comment = response.inputs.opt_comment;
                        prev.input.brunnenring = response.inputs.brunnenring;
                        prev.input.brunnenring600 = response.inputs.brunnenring600 || false;
                        prev.input.brunnenring800 = response.inputs.brunnenring800 || false;
                        prev.input.NW = response.inputs.NW;
                        prev.input.coverOption = response.inputs.coverOption || 'unknown_article_number';
                        prev.input.brunnenringTypeOption = response.inputs.brunnenringTypeOption || '';
                        prev.input.sealingTapeOption = response.inputs.sealingTapeOption || 'ohne_sika_swell';
                        prev.input.coverArticleNumber = response.inputs.coverArticleNumber || '';
                        prev.input.titleBlockOption = response.inputs.titleBlockNumber || '';
                        prev.titleBlockNumber = response.inputs.titleBlockNumber || '';
                        prev.input.titleBlockNumber = response.inputs.titleBlockNumber || '';
                        prev.input.coverEntrance = response.inputs.coverEntrance || coverEntranceDefault;

                        if (response.inputs.titleBlockNumber && response.inputs.titleBlockNumber.length > 0) {
                            prev.input.titleBlock = true;
                        }

                        if (!response.inputs.konus && response.deckplatte) {
                            prev.input.deckplatte = true;
                        }

                        if (!response.deckplatte) {
                            prev.input.konus = true;
                        }

                        if (
                            !checkBrunnenringCompletelySelected(
                                prev.input.brunnenring600,
                                prev.input.brunnenring800,
                                prev.input.brunnenring
                            )
                        ) {
                            prev.input.brunnenringTypeOption = '';

                            if (response.inputs.sealingTapeOption === 'am_brunnenring') {
                                prev.input.sealingTapeOption = 'ohne_sika_swell';
                            }
                        }
                    }

                    if (prev.input.konus || prev.input.deckplatte) {
                        prev.konusOrDeckplatteSelected = true;
                    }

                    return prev;
                }, this.allowNext);
            })
            .then(() => {

                return fetch('/api/komplettschacht/optional-information/cover-articles', {credentials: 'include'})
                    .then(response => response.json())
                    .then(json => {
                        if (json) this.setState({coverArticles: json})

                        if(this.state.input.coverArticleNumber && this.state.input.coverArticleNumber.length > 0 &&
                            this.state.coverArticles[this.state.input.coverArticleNumber] &&
                            this.state.coverArticles[this.state.input.coverArticleNumber].hasTitleBlock === 1 &&
                            this.state.input.coverArticleNumber !== 'unknown_article_number'
                        ) {
                            this.setState(prev => {
                                prev.input.showTitleBlock = true;

                                return prev;
                            }, this.allowNext);
                        }
                    })
            })
            .then(() => {
                return fetch('/api/komplettschacht/optional-information/title-blocks', {credentials: 'include'})
                    .then(response => response.json())
                    .then(json => {
                        if (json) this.setState({titleBlocks: json})
                    })
            })
            .catch(err => {
                if (err.message !== 'done') {
                    console.error(err);

                    this.props.history.push('/error');
                }
            });
    }

    update() {
        this.setState(prev => {
            prev.errorMessage = null;
            prev.fieldsWithError = null;
        });
        fetch(`/api${this.props.location.pathname}`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state.input)
        })
            .then(FetchUtils.checkStatus)
            .then(body => {
                if (body.error) {
                    this.setState(prev => {
                        prev.errorMessage = body.error.message;
                        prev.fieldsWithError = body.error.invalidFields;
                    });
                }

                this.props.history.push(body.next)
            })
            .catch(err => {
                let errorMessage = _.get(err, 'error.message');

                this.setState(prev => {
                    prev.errorMessage = errorMessage;

                    return prev;
                });

                console.error(err);
            })
    }

    allowNext() {
        this.setState(prev => {
            if (!prev.input.belastungsklasse || prev.input.belastungsklasse === '0') {
                prev.allowNext = false;
                return prev;
            }

            if (!prev.konusOrDeckplatteSelected) {
                prev.allowNext = false;
                return prev;
            }

            if (prev.input.brunnenring600 || prev.input.brunnenring800) {
                if (!prev.input.brunnenring || prev.input.brunnenring === '0') {
                    prev.allowNext = false;
                    return prev;
                }
            }

            if (
                prev.input.coverOption === '' ||
                (
                    prev.input.coverOption === 'known_article_number' && prev.input.coverArticleNumber === ''
                )
            ) {
                prev.allowNext = false;
                return prev;
            }

            // brunnenring is completely selected
            if (
                checkBrunnenringCompletelySelected(
                    prev.input.brunnenring600,
                    prev.input.brunnenring800,
                    prev.input.brunnenring
                )
            ) {
                if (!prev.input.brunnenringTypeOption) {
                    prev.allowNext = false;
                    return prev;
                }
            }

            if (prev.input.sealingTapeOption === '') {
                prev.allowNext = false;
                return prev;
            }

            if (prev.invalidCoverArticleNumber) {
                prev.allowNext = false;
                return prev;
            }

            if (prev.coverEntranceConfig && (!prev.input.coverEntrance || prev.input.coverEntrance === '0')) {
                prev.allowNext = false;
                return prev;
            }

            prev.allowNext = true;
            return prev;
        });
    }

    onInputCommentChange(value) {
        this.setState(prev => {
            prev.input.opt_comment = value;

            return prev;
        })
    }

    checkArticleId() {
        this.setState(prev => {
            prev.invalidCoverArticleNumber =
                !Object.prototype.hasOwnProperty.call(prev.coverArticles, prev.input.coverArticleNumber);
            return prev;
        });
    }

    onInputChangeDestructured(key, value) {
        if (key === 'brunnenringTypeOption') {
            this.setState(prev => {
                prev.input.brunnenringTypeOption = value;
                return prev;
            }, this.allowNext);
        }
        if (key === 'sealingTapeOption') {
            this.setState(prev => {
                prev.input.sealingTapeOption = value;
                return prev;
            }, this.allowNext);
        }
        if (value === 'coverArticleNumber') {
            if(this.state.coverArticles[key].hasTitleBlock === 1) {
                this.setState(prev => {
                    prev.input.showTitleBlock = true;
                    prev.input.titleBlock = true;

                    return prev;
                }, this.allowNext);
            } else {
                this.setState(prev => {
                    prev.input.showTitleBlock = false;
                    prev.input.titleBlock = false;
                    prev.input.titleBlockOption = '';

                    return prev;
                }, this.allowNext);
            }

            this.setState(prev => {
                prev.input.coverArticleNumber = key;

                return prev;
            }, this.allowNext);
        }

        if (value === 'unknown_article_number') {
            this.setState(prev => {
                prev.input.showTitleBlock = false;

                return prev;
            }, this.allowNext);
        }

        const coverArticleNumberInputKey = _.get(this.state, 'coverConfig.options.known_article_number.input_key');
        switch (key) {
            case _.get(this.state, 'coverConfig.key'):
                this.setState(prev => {
                    prev.input[key] = value || '';
                    prev.input[coverArticleNumberInputKey] = '';
                    prev.invalidCoverArticleNumber = false;

                    return prev;
                }, this.allowNext);
                break;
            case coverArticleNumberInputKey:
                this.setState(prev => {
                    prev.input[key] = value || '';

                    return prev;
                }, () => {
                    this.checkArticleId();
                    this.allowNext();
                });
                break;
        }
    }

    onInputChange(event) {
        let target = event.target,
            id = target.id,
            value = null;

        switch (id) {
            case _.get(this.state, 'laddersConfig.key'):
            case _.get(this.state, 'belastungsklasseConfig.key'):
            case _.get(this.state, 'brunnenringConfig.key'):
                value = target.value;

                this.setState(prev => {
                    prev.input[id] = value;

                    return prev;
                }, this.allowNext);
                break;
            case _.get(this.state, 'konusConfig.key'):
                value = target.checked;

                this.setState(prev => {
                    prev.input.konus = value;
                    prev.konusOrDeckplatteSelected = value;

                    if (prev.input.deckplatte)
                        prev.input.deckplatte = !value;
                    prev.input.brunnenring600 = false;
                    prev.input.brunnenring800 = false;
                    prev.input.brunnenring = null;

                    if (((!prev.input.brunnenring600 && !prev.input.brunnenring800) || prev.input.konus) &&
                        prev.input.sealingTapeOption === this.state.sealingTapeConfig.options.am_brunnenring.key) {
                        prev.input.sealingTapeOption = this.state.sealingTapeConfig.options.ohne_sika_swell.key
                    }

                    return prev;
                }, this.allowNext);
                break;
            case _.get(this.state, 'deckplatteConfig.key'):
                value = target.checked;

                this.setState(prev => {
                    prev.input.deckplatte = value;
                    prev.konusOrDeckplatteSelected = value;

                    if (prev.input.konus)
                        prev.input.konus = !value;

                    return prev;
                }, this.allowNext);
                break;
            case _.get(this.state, 'brunnenring600Config.key'):
                value = target.checked;

                this.setState(prev => {
                    prev.input.brunnenring600 = value;

                    if (prev.input.brunnenring800)
                        prev.input.brunnenring800 = !value;

                    if (!value &&
                        prev.input.sealingTapeOption === this.state.sealingTapeConfig.options.am_brunnenring.key) {
                        prev.input.sealingTapeOption = this.state.sealingTapeConfig.options.ohne_sika_swell.key
                    }

                    return prev;
                }, this.allowNext);
                break;
            case _.get(this.state, 'brunnenring800Config.key'):
                value = target.checked;

                this.setState(prev => {
                    prev.input.brunnenring800 = value;

                    if (prev.input.brunnenring600)
                        prev.input.brunnenring600 = !value;

                    if (!value &&
                        prev.input.sealingTapeOption === this.state.sealingTapeConfig.options.am_brunnenring.key) {
                        prev.input.sealingTapeOption = this.state.sealingTapeConfig.options.ohne_sika_swell.key
                    }

                    return prev;
                }, this.allowNext);

                break;
            case 'titleBlockOption':
                value = target.checked;

                if (value === false) {
                    this.setState(prev => {
                        prev.input.titleBlock = value;
                        prev.input.titleBlockOption = '';
                        prev.input.titleBlockNumber = '';

                        return prev;
                    }, this.allowNext);
                }

                this.setState(prev => {
                    prev.input.titleBlock = value;

                    return prev;
                }, this.allowNext);

                break;
            case 'titleBlocks':
                this.setState(prev => {
                    prev.input.titleBlockOption = target.value;
                    prev.input.titleBlockNumber = target.value;

                    return prev;
                }, this.allowNext);
                break;
            case _.get(this.state, 'coverEntranceConfig.key'):
                this.setState(prev => {
                    prev.input.coverEntrance = target.value;

                    return prev;
                }, this.allowNext);
                break;
        }
    }

    onBack() {
        fetch(`/api${this.props.location.pathname}/back`, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((result) => {
                result.json().then(body => {
                    this.props.history.push(body.previous);
                });
            });
    }

    render() {
        const {t} = this.props;

        return (
            <div>
                <OptionalInformation
                    t={t}
                    {...this.props}
                    allowNext={this.state.allowNext}
                    onInputChange={this.onInputChange}
                    onInputChangeDestructured={this.onInputChangeDestructured}
                    onInputCommentChange={this.onInputCommentChange}
                    belastungsklasseConfig={this.state.belastungsklasseConfig}
                    laddersConfig={this.state.laddersConfig}
                    konusConfig={this.state.konusConfig}
                    brunnenringConfig={this.state.brunnenringConfig}
                    brunnenring600Config={this.state.brunnenring600Config}
                    brunnenring800Config={this.state.brunnenring800Config}
                    kommentarConfig={this.state.kommentarConfig}
                    deckplatteConfig={this.state.deckplatteConfig}
                    leiter={this.state.input.leiter}
                    belastungsklasse={this.state.input.belastungsklasse}
                    konusOrDeckplatteSelected={this.state.konusOrDeckplatteSelected}
                    konus={this.state.input.konus}
                    brunnenring={this.state.input.brunnenring}
                    brunnenring600={this.state.input.brunnenring600}
                    brunnenring800={this.state.input.brunnenring800}
                    opt_comment={this.state.input.opt_comment}
                    deckplatte={this.state.input.deckplatte}
                    price={this.state.input.price}
                    weight={this.state.input.weight}
                    fieldsWithError={this.state.fieldsWithError}
                    errorMessage={this.state.errorMessage}
                    NW={this.state.input.NW}
                    coverConfig={this.state.coverConfig}
                    coverOption={this.state.input.coverOption}
                    brunnenringTypeConfig={this.state.brunnenringTypeConfig}
                    brunnenringTypeOption={this.state.input.brunnenringTypeOption}
                    sealingTapeConfig={this.state.sealingTapeConfig}
                    sealingTapeOption={this.state.input.sealingTapeOption}
                    coverArticleNumber={this.state.input.coverArticleNumber}
                    selectedCoverArticle={this.state.coverArticles[this.state.input.coverArticleNumber] || {}}
                    coverArticles={this.state.coverArticles}
                    titleConfig={this.state.titleBlockConfig}
                    titleBlocks={this.state.titleBlocks}
                    setTitleBlock={this.state.input.titleBlock}
                    showTitleBlock={this.state.input.showTitleBlock}
                    titleBlockOption={this.state.input.titleBlockOption}
                    coverEntranceConfig={this.state.coverEntranceConfig}
                    coverEntrance={this.state.input.coverEntrance}
                />
            </div>
        );
    }
}

OptionalInformationData.propTypes = {
    eventHandler: PropTypes.any,
    history: PropTypes.any,
    location: PropTypes.any,
    t: PropTypes.any,
    showSideProtocol: PropTypes.bool,
    showProgressBar: PropTypes.bool,
    toggleProgressBar: PropTypes.func,
    toggleSideProtocol: PropTypes.func
};

export default OptionalInformationData;
